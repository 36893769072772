<template>
  <div>
    <div class="confirmation-top">
      <div class="confirmation-base">
        <div class="confirmation-img">
          <img v-if="isPaymentSuccess"  src="@/assets/icons/success_icon.png" alt="success" />
          <img v-else  src="@/assets/icons/failed_icon.png" alt="success" />

        </div>
        <p class="loading-primarytext">{{ progresskey.payementProcess }}</p>
        <p class="loading-text">{{ progresskey.paymentUnderprocess }}</p>
        <div class="line-border"></div>
        <div class="confirmation-des">
          <ul>
            <li v-show="paymentdatabase.email">
              <div class="left-des"> {{ $t("email id") }} </div>
              <div class="right-des">{{ trimString(paymentdatabase.email) }}</div>
            </li>
            <li v-show="paymentdatabase.mobileno">
              <div class="left-des">{{ $t("Mobile Number") }}</div>
              <div class="right-des">{{ paymentdatabase.mobileno }}</div>
            </li>
       
            <li v-if="paymentdatabase.payment_id">
              <div class="left-des">{{ $t("Payment Id") }}</div>
              <div class="right-des">{{ paymentdatabase.payment_id }}</div>
            </li>
            <li v-if="paymentdatabase.orderid">
              <div class="left-des">{{ $t("Order Id") }}</div>
              <div class="right-des">{{ paymentdatabase.orderid }}</div>
            </li>

            <li v-if="paymentdatabase.planname">
              <div class="left-des">{{ $t("Plan Name") }}</div>
              <div class="right-des">{{ paymentdatabase.planname }}</div>
            </li>

            <li>
              <div class="left-des">{{ $t("Plan Price") }}</div>
              <div class="right-des success-price"> 
                <div>{{paymentdatabase.currency }}</div>
               <div>{{ (paymentdatabase.amount / 100) }}</div>
              </div>
            </li>
          </ul>
     
        </div>

        <div>
            <button
              class="button-primary btn-size-fix btn-success"
              @click="actHome"
            >
              {{ progresskey.buttontxt }}
            </button>
          </div>
      </div>

      
    </div>
 
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { store } from "@/store/store";


export default {
  data() {
    return {
      progresskey: {},
      paymentdatabase: {},
      isPaymentSuccess: true
    };
  },
  created() {

    this.paymentdatabase = this.$route.query;
    this.isPaymentSuccess = this.paymentdatabase.payment_status == 'success' ? true : false;
    this.progresskey.payementProcess = this.isPaymentSuccess ? this.$t("Payment Success !") : this.$t("Payment Failed !");
    this.progresskey.paymentUnderprocess = this.isPaymentSuccess ? this.$t("Plan subscription is successfull") : this.$t("We couldn't process your payment");
    this.progresskey.buttontxt = this.isPaymentSuccess ? this.$t("Explore Premium") : this.$t("Retry");
    this.getSubscriptionList();
  },
  methods: {
    trimString (str) {
      if (str && str.length > 28) {
       return str.substr(0, 27) + '...'
      }
      return str;
    
    },
    getSubscriptionList () {
      let payload = {
        subscriptionstatus: "ALL",
      };
      let subscriberDetails = {}
      try {

        if (localStorage.getItem("subscriberDetails")) {
          subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
        }
      } catch (e) {
        console.log(e)
      }
     

      if (this.paymentdatabase.transactionpurpose != "SUBSCRIPTION") {
        let params = {
            payment_id: this.paymentdatabase.payment_id,
            amount: this.paymentdatabase.amount / 100,
            value: this.paymentdatabase.amount / 100,
            currency: this.paymentdatabase.currency,
            currency_code: this.paymentdatabase.currency,
            email: this.paymentdatabase.email || subscriberDetails.email,
            mobileno: this.paymentdatabase.mobileno || subscriberDetails.mobileno,
            plan_name: this.paymentdatabase.planname,
            platform: this.getPlatform(),
            platform_type: this.getPlatformType().browser,
            transactionpurpose: this.paymentdatabase.transactionpurpose
          };
          fbq("track", "Purchase", params);
           if (window.dataLayer) {
            window.dataLayer.push({event: "Purchase", eventModel: params})
          }
        return;

      }

      store.dispatch("listSubscription", payload).then((response) => {
        if (!response.data.reason) {
          store.commit("setSubscriptionList", response.data.data);
          localStorage.setItem("subscriptionDetails", JSON.stringify(response.data.data[0]));

          let activePlan = response.data.data[0];
          let params = {
            payment_mode: activePlan.gwprovider,
            amount: this.paymentdatabase.amount / 100,
            value: this.paymentdatabase.amount / 100,
            currency: this.paymentdatabase.currency,
            subscriberid: activePlan.subscriberid,
            currency_code: this.paymentdatabase.currency,
            planstartdate: activePlan.planstartdate,
            nextbilling: activePlan.nextbilling,
            payment_id: this.paymentdatabase.payment_id,
            email: this.paymentdatabase.email || subscriberDetails.email,
            mobileno: this.paymentdatabase.mobileno || subscriberDetails.mobileno,
            plan_id: activePlan.planid,
            plan_name: activePlan.planname,
            platform: this.getPlatform(),
            platform_type: this.getPlatformType().browser,
            transactionpurpose: this.paymentdatabase.transactionpurpose
          };

        console.log(params)
        if (this.paymentdatabase.transactionpurpose == "SUBSCRIPTION") {
          fbq("track", "Subscribe", params);
          
          if (window.dataLayer) {
            window.dataLayer.push({event: "Subscribe", eventModel: params})
          }
         
        } else {
          fbq("track", "Purchase", params);

          if (window.dataLayer) {
            window.dataLayer.push({event: "Purchase", eventModel: params})
          }
        }

        }
      });
    },
    actHome () {
      if (!this.isPaymentSuccess) {
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: "seeAllPlans", params: {lang: currentLanguage } });
        return;
      }
      if (localStorage.getItem('paymentGetwaySaveUrl')) {
        window.location.href = localStorage.getItem('paymentGetwaySaveUrl');
        localStorage.removeItem('paymentGetwaySaveUrl');
      } else {
        if (localStorage.getItem('setDisplayLanguageCode') == 'eng') {
          window.location.href = "/";
        } else {
          window.location.href = window.location.origin + "/" + localStorage.getItem('setDisplayLanguageCode');
        }

      }
    }
  },
  mixins: [Utility]
};
</script>

<style lang="scss" scoped>
.confirmation-top {
  background: #212121;
  border-radius: 10px;
  width: 50%;
  height: 30rem;
  margin: 5rem auto 0rem auto;
}

.confirmation-img {
  margin: 0 auto;
  position: relative;
  height: 8rem;
}
.confirmation-base {
  text-align: center;
  .btn-success {
    width: 50%;
  }

}

.loading-text {
  color: #ffffffb2;
  font-size: 0.9rem;
  margin-top: 0.3rem;
}
.line-border {
  border: 1px;
  background: rgba(255, 255, 255, 0.16);
  height: 2px;
  margin-top: 1rem;
}

.loading-primarytext {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.1rem;
  // margin-top: 1rem;
}
.confirmation-des {
  width: 100%;
  margin: 1rem auto;
  padding: 0px 3rem;
  border-radius: 3px;
  ul li {
    display: flex;
    justify-content: space-around;
    padding: 0.2rem 0.5rem;
    align-items: baseline;
    border-radius: 3px;
    // &:nth-child(even) {
    //   background: rgba(255, 255, 255, 0.06);
    // }

    .left-des {
      color: #ffffffb2;
      width: 10rem;
      text-align: left;
      margin-right: 5rem;
      margin-bottom: 0.5rem;
    }
    .right-des {
      color: white;
      width: 10rem;
      text-align: left;
    }
    .success-price {
      display: flex;
      div {
        margin-right: 0.2rem;
      }
    }
  }
}

@media (max-width: 320px) {
  .confirmation-top {
    background: #212121;
    padding: 1.5rem;
    border-radius: 10px;
    width: 70%;
    height: 40rem;
  }
  .confirmation-des {
    padding: 0px 0px; 
  }
}

@media (min-width: 321px) and (max-width: 468px) {
  .confirmation-top {
    background: #212121;
    padding: 1.5rem;
    border-radius: 10px;
    width: 80%;
    height: 40rem;
    margin: 5rem auto 0rem auto;
  }
  .confirmation-des ul li {
    display: block;
    .left-des {
      width: 100%;
    }
    .right-des {
      width: 100%;
    }
  }
  .confirmation-des {
    padding: 0px 0px; 
  }


}
@media (min-width: 993px) and (max-width: 1200px) {
  .confirmation-top {
    width: 70%;
  }

}

@media (min-width: 469px) and (max-width: 992px) {
  .confirmation-top {
    background: #212121;
    padding: 1.5rem;
    border-radius: 10px;
    width: 60%;
    height: 40rem;
    margin: 5rem auto 0rem auto;

  }
  .confirmation-des ul li {
    display: block;
    .left-des {
      width: 100%;
    }
    .right-des {
      width: 100%;
    }

  }
  .confirmation-des {
    padding: 0px 0px; 
  }
}


</style>
